
@font-face {
  font-family: Sarabun;
  src: url('../font/Sarabun-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Sarabun;
  src: url('../font/Sarabun-Bold.ttf');
  font-weight: bold;
}

body {
  font-family: 'Sarabun';
  background-color: #F6F7FB;
  font-size: 16px;
  margin: 0px;
}

.editor-view {
  top: 0px !important;
  height: 100vh;
  margin: 0px;
  max-width: 100%;
  z-index: 10;
}

.editor-view > .ant-modal-content {
  background-color: transparent;
}

.editor-view > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

@page {
  size: portrait;
  width: 210mm;
  height: 297mm;
  margin: 0px;
  margin-bottom: 10mm;

  background-color: white;
}

#widthie {
  display: none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: red;
}

.filter-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}

.filter-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}

.filter-collapse {
  background-color:white;
  min-width: 180px;
}

.filter-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-expand-icon {
  width: 100%;
}